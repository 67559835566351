import React from "react";
import "./Terms.css";
import { green } from "@material-ui/core/colors";

class TermsFizAndz extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <div>
        <div className="tHead">«ՄԻԿՐՈ ԿԱՊԻՏԱԼ ՀԱՅԱՍՏԱՆ» ՈՒՎԿ ՓԲԸ</div>
        <div className="tHead">
          Հաստատված է Տնօրենների Խորհրդի Թիվ 17/23 որոշմամբ
        </div>
        <div className="tHeadSub">Սակագների փոփոխությունը հաստատված է Վարչության թիվ 05/24 որոշմամբ</div>
        <div className="mainHeader">
          ՎԱՐԿ «ՄԻԿՐՈ» ՎԱՐԿԱՏԵՍԱԿԻ ՊԱՅՄԱՆՆԵՐԸ ԵՎ ՍԱԿԱԳՆԵՐԸ
        </div>
        <div className="termsContainer">
          <table className="termsTable smallAmount">
            <tbody>
              <tr>
                <td colSpan="2">Վարկի գումար (ՀՀ դրամ)</td>
                <td colSpan="3">350,000-3,000,000</td>
              </tr>
              <tr>
                <td colSpan="2">Վարկի ժամկետ (ամիս)</td>
                <td colSpan="3">12-60</td>
              </tr>
              <tr>
                <td colSpan="2">Անվանական տարեկան տոկոսադրույք</td>
                <td colSpan="3">21%</td>
              </tr>
              <tr>
                <td colSpan="2">Տարեկան փաստացի տոկոսադրույք</td>
                <td colSpan="3">45.9%-46.8%</td>
              </tr>
              <tr>
                <td colSpan="2">Սպասարկման ամենամսյա վճար</td>
                <td colSpan="3">1.4%-1.5% վարկի մնացորդի վրա</td>
              </tr>
              <tr>
                <td colSpan="2">Տրամադրման միանվագ միջնորդավճար</td>
                <td colSpan="3">0%</td>
              </tr>
              <tr>
                <td colSpan="2">Ապահովվածություն(նվազագույն)</td>
                <td colSpan="3">
                  <ul>
                    <li>
                      մինչև 1,000,000 ՀՀ դրամ՝ նաև առանց երաշխավորության
                    </li>
                    <li>
                      1,000,001 – 3,000,000 ՀՀ դրամ՝ 1 – 2 երաշխավոր
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="2">Վաղաժամկետ մարում</td>
                <td colSpan="3">0%</td>
              </tr>
              <tr>
                <td colSpan="2">Տույժեր</td>
                <td colSpan="3">
                  ժամկետանց տոկոսագումարի և մայր գումարի նկատմամբ օրական 0.1%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h2>«ՄԻԿՐՈ» ՎԱՐԿԱՏԵՍԱԿԻ ՆԿԱՐԱԳԻՐԸ ԵՎ ՀԻՄՆԱԿԱՆ ԲՆՈՒԹԱԳՐԵՐԸ</h2>
        <div className="termsContainer">
          <table className="termsTable mainDesc">
            <tbody>
              <tr>
                <td colSpan="1"></td>
                <td colSpan="2">ՉԱՓԱՆԻՇՆԵՐ</td>
                <td colSpan="2">ՀԻՄՆԱԿԱՆ ԲՆՈՒԹԱԳՐԵՐ</td>
              </tr>
              <tr>
                <td colSpan="1">1</td>
                <td colSpan="2">Վարկառուի նկարագիր</td>
                <td colSpan="2">
                  ՀՀ ռեզիդենտ ֆիզիկական անձինք (այդ թվում միկրոձեռնարկատերեր, գյուղատնտեսությամբ զբաղվող ֆիզիկական
                  անձինք, աշխատավարձ ստացող անհատներ և այլն)
                </td>
              </tr>
              <tr>
                <td colSpan="1">2</td>
                <td colSpan="2">
                  Վարկառուին/երաշխավորին ներկայացվող պահանջներ
                </td>
                <td colSpan="2">
                  <ul>
                    <li>
                      Վարկառուի տարիքը 21 – 65 տարեկան` համապատասխանաբար դիմելու պահին և վարկային
                      պայմանագրի ժամկետի ավարտի պահին,
                    </li>
                    <li>
                      Վարկառուի և երաշխավորների դրական վարկային պատմություն՝ «Միկրո Կապիտալ Հայաստան»
                      ՈՒՎԿ ՓԲԸ–ի ներքին չափանիշներին համապատասխան,
                    </li>
                    <li>
                      «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ վճարունակության գնահատման ներքին չափանիշներին
                      համապատասխանող վարկառուի եկամուտների առկայություն` առնվազն 6 ամիս,
                    </li>
                    <li>
                      Ֆինանսական երաշխավորներ կարող են համարվել ֆիզիկական/իրավաբանական անձիք, ովքեր ունեն
                      փաստաթղթորեն հիմնավորված կայուն եկամուտ կամ անշարժ գույք, որը համարժեք է վարկի գումարին,
                    </li>
                    <li>
                      Վարկառուի և երաշխավորների համապատասխանություն «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ
                      ՓԲԸ - ի վարկային քաղաքականությանը և ներքին կանոնակարգերով սահմանված այլ չափանիշներին:
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">3</td>
                <td colSpan="2">Վարկի նպատակ</td>
                <td colSpan="2">հաճախորդի կենսապայմանների բարելավում</td>
              </tr>
              <tr>
                <td colSpan="1">4</td>
                <td colSpan="2">Վարկի արժույթ</td>
                <td colSpan="2">ՀՀ դրամ</td>
              </tr>
              <tr>
                <td colSpan="1">5</td>
                <td colSpan="2">
                  Վարկի տրամադրման նվազագույն և առավելագույն սահմանաչափեր
                </td>
                <td colSpan="2">350,000 – 3,000,000 ՀՀ դրամ</td>
              </tr>
              <tr>
                <td colSpan="1">6</td>
                <td colSpan="2">Վարկի նվազագույն և առավելագույն ժամկետներ</td>
                <td colSpan="2">12 - 60 ամիս</td>
              </tr>
              <tr>
                <td colSpan="1">7</td>
                <td colSpan="2">Մարման ժամանակացույց</td>
                <td colSpan="2">
                  <ul>
                    <li>
                      Անուիտետային վճարումներ – վճարումներ, որի դեպքում վարկի ամբողջ ժամանակահատվածի
                      ընթացքում յուրաքանչյուր ամիս հաճախորդը կատարում է միևնույն չափի վճարումներ,
                    </li>
                    <li>
                      Հավասարաչափ վճարումներ – հաճախորդը վճարում է յուրաքանչյուր ամիս հավասարաչափ մայր
                      գումար, որի արդյունքում տոկոսավճարների հանրագումարը ամեն ամիս նվազում է,
                    </li>
                    <li>
                      Ճկուն մարման ժամանակացույց – վարկի մայր գումարի մարումները բաշխվում են մարման
                      ժամանակաշրջանի վրա անհավասարաչափ:
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">8</td>
                <td colSpan="2">Արտոնյալ ժամկետ մայր գումարի համար</td>
                <td colSpan="2">
                  առավելագույնը 3 ամիս
                </td>
              </tr>
              <tr>
                <td colSpan="1">9</td>
                <td colSpan="2">Վարկի տրամադրման եղանակ</td>
                <td colSpan="2">
                  Անկանխիկ
                </td>
              </tr>
              <tr>
                <td colSpan="1">10</td>
                <td colSpan="2">Վաղաժամկետ մարում</td>
                <td colSpan="2">
                  <ul>
                    <li>
                      Վարկառուն իրավունք ունի վաղաժամկետ ամբողջական կամ մասնակի մարելու վարկը և կատարելու
                      պայմանագրով սահմանված այլ պարտականությունները առանց տույժ վճարելու,
                    </li>
                    <li>
                      Վարկի վաղաժամկետ ամբողջական մարման պարագայում վարկառուն վճարում է վճարման օրվա դրությամբ
                      հաշվարկված վարկի սպասարկման և այլ վճարները, տոկոսները և վարկի մայր գումարը,
                    </li>
                    <li>
                      Վարկի վաղաժամկետ մասնակի մարման պարագայում գումարը ուղղվում է վարկի մայր գումարի մարմանը և
                      համամասնորեն նվազեցվում են վարկի հետագա ամսվա (ամիսների) մարման ենթակա տոկոսագումարները,
                      եթե հաճախորդը չի տրամադրել այլ հանձնարարական:
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">11</td>
                <td colSpan="2">
                  Զուգահեռ վարկեր վարկառուների խմբին/փոխկապակցված անձանց տվյալ
                  վարկատեսակի շրջանակներում
                </td>
                <td colSpan="2">
                  <ul>
                    <li>Զուգահեռ վարկերի առավելագույն քանակ - 2</li>
                    <li>
                      Զուգահեռ վարկերի առավելագույն գումարը – 3,000,000 ՀՀ դրամ
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">12</td>
                <td colSpan="2">Տրանշեր</td>
                <td colSpan="2">-</td>
              </tr>
              <tr>
                <td colSpan="1">13</td>
                <td colSpan="2">Ապահովվածություն (նվազագույն)</td>
                <td colSpan="2">
                  <ul>
                    <li>
                      առանց ապահովվածության,
                    </li>
                    <li>
                      3-րդ անձանց երաշխավորություն
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">14</td>
                <td colSpan="2">Վճարունակության գնահատում</td>
                <td colSpan="2">
                  <ul>
                    <li>հաճախորդի սոցիալական բնութագիր,</li>
                    <li>հաճախորդի վարկային պատմություն,</li>
                    <li>
                      իրավական ռիսկեր,
                    </li>
                    <li>
                      Ֆինանսատնտեսական վերլուծություն, համաձայն վարկատեսակի գծով ֆինանսատնտեսական
                      վերլուծության ուղեցույցի
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">15</td>
                <td colSpan="2">Վարկերի դիտարկում (հաստատում/մերժում)</td>
                <td colSpan="2">
                  Համաձայն «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ Վարկավորման ընթացակարգով սահմանված
                  որոշումների կայացման իրավասությունների
                </td>
              </tr>
              <tr>
                <td colSpan="1">16</td>
                <td colSpan="2">Որոշման կայացման ժամկետ</td>
                <td colSpan="2">
                  0.5 աշխատանքային օր (հաճախորդի կողմից փաստաթղթերի ամբողջական փաթեթի առկայության դեպքում)
                </td>
              </tr>
              <tr>
                <td colSpan="1">17</td>
                <td colSpan="2">Որոշման մասին տեղեկացնելու ժամկետ</td>
                <td colSpan="2">1 աշխատանքային օր</td>
              </tr>
              <tr>
                <td colSpan="1">18</td>
                <td colSpan="2">Վարկը տրամադրելու ժամկետ</td>
                <td colSpan="2">
                  1 աշխատանքային օր (վարկի ձևակերպման համար անհրաժեշտ փաստաթղթերը ներկայացնելու դեպքում)
                </td>
              </tr>
              <tr>
                <td colSpan="1">19</td>
                <td colSpan="2">Վարկի ձևակերպման վայրը</td>
                <td colSpan="2">
                  «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ գրասենյակներում կամ «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ
                  ՓԲԸ հետ համագործակցող/գործընկեր կազմակերպության գործունեության վայրում
                </td>
              </tr>
              <tr>
                <td colSpan="1">20</td>
                <td colSpan="2">Վարկային հայտի մերժման հնարավոր պատճառներ</td>
                <td colSpan="2">
                  <ul>
                    <li>
                      սահմանված նվազագույն պահանջներին չբավարարման դեպքում (1, 2 և 13 կետեր),
                    </li>
                    <li>
                      հաճախորդի կողմից անհրաժեշտ փաստաթղթերի և այլ հիմնավորումների չներկայացման, կեղծ
                      փաստաթղթերի կամ անարժանահավատ տեղեկատվության ներկայացման դեպքում,
                    </li>
                    <li>
                      վարկային կոմիտեն չի բավարարել վարկային հայտը` հաշվի առնելով գործարքի ռիսկայնությունը կամ
                      առկա այլ ռիսկերը, վարկային կոմիտեի իրավասությամբ սահմանված այլ դեպքերում, այդ թվում.
                    </li>
                    <li>
                      <ul>
                        <li>դրական վարկային պատմության բացակայություն,</li>
                        <li>իրավական ռիսկերի առկայություն,</li>
                        <li>
                          եկամուտների անբավարարություն
                        </li>
                      </ul>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">21</td>
                <td colSpan="2">Մոնիթորինգ</td>
                <td colSpan="2">
                  Համաձայն «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ – ի Վարկավորման
                  ընթացակարգի
                </td>
              </tr>
              <tr>
                <td colSpan="1">22</td>
                <td colSpan="2">Անհրաժեշտ փաստաթղթերի ցանկ</td>
                <td colSpan="2">
                  <ul>
                    <li>
                      Վարկառուի անձնագիր և սոցիալական քարտ կամ նույնականացման քարտ,
                    </li>
                    <li>Վարկառուի եկամուտները հավաստող փաստաթղթեր/ինֆորմացիա,</li>
                    <li>Այլ փաստաթղթեր (Վարկային Կոմիտեի պահանջով)</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">23</td>
                <td colSpan="2">
                  Տարեկան տոկոսադրույք և վարկատեսակի այլ պայմաններ
                </td>
                <td colSpan="2">
                  Վարկատեսակի տոկոսադրույքը, տրամադրման վճարը և այլ պայմանները սահմանվում են «Միկրո Կապիտալ
                  Հայաստան» ՈՒՎԿ ՓԲԸ լիազոր մարմնի որոշմամբ սահմանված վարկատեսակների պայմաններով և
                  սակագներով, որի մասին հաճախորդները ծանուցվում են կազմակերպության բոլոր տարածքային
                  ստորաբաժանումներում, ինչպես նաև պայմանները նկարագրող ինֆորմացիոն թերթիկների և կազմակերպության
                  ինտերնետային կայքի միջոցով
                </td>
              </tr>
              <tr>
                <td colSpan="1">24</td>
                <td colSpan="2">Այլ պայմաններ</td>
                <td colSpan="2">
                  1 (մեկ) հաճախորդին տրամադրված առանց գրավ վարկերի ընդհանուր մնացորդը բոլոր վարկատեսակների գծով
                  չի կարող գերազանցել 15,000,000 ՀՀ դրամը:
                </td>
              </tr>
              <tr>
                <td colSpan="1">ԶԳՈՒՇԱՑՈՒՄ</td>
                <td colSpan="4">
                  <ul>
                    <li>
                      ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ՎԱՐԿԻ ՄԱՅՐ ԳՈՒՄԱՐԻ, ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԻ ԵՎ
                      ՍՊԱՍԱՐԿՄԱՆ ՎՃԱՐՆԵՐԻ ՄԱՐՈՒՄՆԵՐԸ ԺԱՄԱՆԱԿԻՆ ԵՎ ՊԱՏՇԱՃ
                      ՉԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ ՎԱՐԿԱՌՈՒԻ ԵՎ ԵՐԱՇԽԱՎՈՐԻ ՄԱՍԻՆ
                      ՀԱՄԱՊԱՏԱՍԽԱՆ ՏԵՂԵԿԱՏՎՈՒԹՅՈՒՆԸ ԿԳՐԱՆՑՎԻ ԿԲ ՎԱՐԿԱՅԻՆ
                      ՌԵԳԻՍՏՐՈՒՄ ԵՎ «ԱՔՌԱ» ՎԱՐԿԱՅԻՆ ԲՅՈՒՐՈՅԻ ՌԵԳԻՍՏՐՈՒՄ,
                    </li>
                    <li>
                      ՎԱՐԿԱՌՈՒՆ ԻՐ ՊԱՅՄԱՆԱԳՐԱՅԻՆ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ԿԱՏԱՐՄԱՆ
                      ՀԱՄԱՐ ՆՅՈՒԹԱԿԱՆ ՊԱՏԱՍԽԱՆԱՏՎՈՒԹՅՈՒՆ Է ԿՐՈՒՄ ՆԱԵՎ
                      ՍԵՓԱԿԱՆՈՒԹՅԱՆ ԻՐԱՎՈՒՆՔՈՎ ԻՐԵՆ ՊԱՏԿԱՆՈՂ ՑԱՆԿԱՑԱԾ ԱՅԼ
                      ԳՈՒՅՔՈՎ (ՏՈՒՆ, ԲՆԱԿԱՐԱՆ, ԱՎՏՈՄԵՔԵՆԱ, ԱՇԽԱՏԱՎԱՐՁ ԵՎ ԱՅԼՆ),
                      ԵԹԵ ԱՌԿԱ ԳՐԱՎԻ ԱՌԱՐԿԱՆ ԲԱՎԱՐԱՐ ՉԷ «ՄԻԿՐՈ ԿԱՊԻՏԱԼ ՀԱՅԱՍՏԱՆ»
                      ՈՒՎԿ ՓԲԸ – Ի ՆԿԱՏՄԱՄԲ ՍՏԱՆՁՆԱԾ ԻՐ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ
                      ԱՄԲՈՂՋԱԿԱՆ ԵՎ ՊԱՏՇԱՃ ԿԱՏԱՐՄԱՆ ՀԱՄԱՐ,
                    </li>
                    <li>
                      ՎԱՐԿԱՌՈՒՆ ԵՎ ԵՐԱՇԽԱՎՈՐԸ ԻՐԵՆՑ ԱՆՁՆԱԿԱՆ ԱՄԲՈՂՋ ԳՈՒՅՔՈՎ
                      (ՏՈՒՆ, ՄԵՔԵՆԱ, ԱՇԽԱՏԱՎԱՐՁ ԿԱՄ ԱՅԼ ԳՈՒՅՔ) ՀԱՎԱՍԱՐԱՊԵՍ
                      ՊԱՏԱՍԽԱՆԱՏՎՈՒԹՅՈՒՆ ԵՆ ԿՐՈՒՄ «ՄԻԿՐՈ ԿԱՊԻՏԱԼ ՀԱՅԱՍՏԱՆ» ՈՒՎԿ
                      ՓԲԸ – Ի ԱՌՋԵՎ ՍՏԱՆՁՆԱԾ ԻՐԵՆՑ ՎԱՐԿԱՅԻՆ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ
                      ԿԱՏԱՐՄԱՆ ՀԱՄԱՐ,
                    </li>
                    <li>
                      ՎԱՐԿԱՌՈՒԻ ԵՎ/ԿԱՄ ԵՐԱՇԽԱՎՈՐԻ ԱՆՁՆԱԿԱՆ ԳՈՒՅՔԸ ԿԱՐՈՂ Է
                      ԲՌՆԱԳԱՆՁՎԵԼ ՕՐԵՆՔՈՎ ՍԱՀՄԱՆՎԱԾ ԿԱՐԳՈՎ, ԵԹԵ ՎԱՐԿԱՌՈՒՆ
                      ԺԱՄԱՆԱԿԻՆ ՉԻ ԿԱՏԱՐՈՒՄ ԻՐ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԻ, ՍՊԱՍԱՐԿՄԱՆ
                      ՎՃԱՐՆԵՐԻ ԵՎ ՎԱՐԿԻ ԳՈՒՄԱՐԻ ՄԱՐՈՒՄՆԵՐԸ,
                    </li>
                    <li>
                      ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆԸ ՉԿԱՏԱՐԵԼՈՒ ԿԱՄ ԹԵՐԻ
                      ԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ ՎԱՐԿԱՏՈՒՆ 3 (ԵՐԵՔ) ԱՇԽԱՏԱՆՔԱՅԻՆ ՕՐ ՀԵՏՈ
                      ԱՅԴ ՏՎՅԱԼՆԵՐԸ ՈՒՂԱՐԿՈՒՄ Է ՎԱՐԿԱՅԻՆ ԲՅՈՒՐՈ, ՈՐՏԵՂ
                      ՁԵՎԱՎՈՐՎՈՒՄ Է ՎԱՐԿԱՌՈՒԻ ՎԱՐԿԱՅԻՆ ՊԱՏՄՈՒԹՅՈՒՆԸ: ՎԱՐԿԱՌՈՒՆ
                      ԻՐԱՎՈՒՆՔ ՈՒՆԻ ՎԱՐԿԱՅԻՆ ԲՅՈՒՐՈՅԻՑ ՏԱՐԵԿԱՆ ՄԵԿ ԱՆԳԱՄ ԱՆՎՃԱՐ
                      ՁԵՌՔ ԲԵՐԵԼ ԻՐ ՎԱՐԿԱՅԻՆ ՊԱՏՄՈՒԹՅՈՒՆԸ: ՎԱՏ ՎԱՐԿԱՅԻՆ
                      ՊԱՏՄՈՒԹՅՈՒՆԸ ԿԱՐՈՂ Է ԽՈՉԸՆԴՈՏԵԼ ՎԱՐԿԱՌՈՒԻ ԱՊԱԳԱՅՈՒՄ ՎԱՐԿ
                      ՍՏԱՆԱԼՈՒ ԺԱՄԱՆԱԿ:
                    </li>
                    <li>
                      ՏՐԱՄԱԴՐՎԱԾ ՎԱՐԿԱՅԻՆ ՊԱՅՄԱՆԱԳՐԻ ԳՈՐԾՈՂՈՒԹՅԱՆ ԸՆԹԱՑՔՈՒՄ
                      ՎԱՐԿԱՌՈՒԻ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ՎԵՐԱԲԵՐՅԱԼ ՔԱՂՎԱԾՔՆԵՐԸ ԱՄԻՍԸ
                      ՄԵԿ ԱՆԳԱՄ ՈՒՂԱՐԿՎՈՒՄ ԵՆ ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ՏՐԱՄԱԴՐՎԱԾ
                      ԷԼԵԿՏՐՈՆԱՅԻՆ ՓԱՍՏԻ ՀԱՍՑԵԻՆ, ԵԹԵ ՎԱՐԿԱՌՈՒՆ ՔԱՂՎԱԾՔԻ ՍՏԱՑՄԱՆ
                      ԱՅԼ ԵՂԱՆԱԿ ՉԻ ԸՆՏՐՈՒՄ: ԱՆԿԱԽ ՆՇՎԱԾ ԵՂԱՆԱԿՈՎ ՔԱՂՎԱԾՔԸ
                      ՍՏԱՆԱԼՈՒՑ՝ ՎԱՐԿԱՌՈՒՆ ԻՐԱՎՈՒՆՔ ՈՒՆԻ ԱՅՆ ՍՏԱՆԱԼ (ԴԻՄԵԼՈՒ
                      ՊԱՀԻՆ) ՎԱՐԿԱՏՈՒԻ ՏԱՐԱԾՔՈՒՄ՝ ԱՌՁԵՌՆ։ ՔԱՂՎԱԾՔՆԵՐԻ
                      ՏՐԱՄԱԴՐՈՒՄՆ ԱՆՎՃԱՐ Է:
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="termsContainer">
            <table className="termsTable mainDesc">
              <tbody>
                <tr>
                  <td  style={{ backgroundColor: "green" }}>
                    ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԱՇՎԱՐԿՈՒՄԸ
                  </td>
                </tr>
                <tr>
                  <td colSpan="5">
                    ՈՒՇԱԴՐՈՒԹՅՈՒՆ՝ ՎԱՐԿԻ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԸ ՀԱՇՎԱՐԿՎՈՒՄ ԵՆ
                    ԱՆՎԱՆԱԿԱՆ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԻՄԱՆ ՎՐԱ: ԻՍԿ ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ
                    ՏՈԿՈՍԱԴՐՈՒՅՔԸ ՑՈՒՅՑ Է ՏԱԼԻՍ, ԹԵ ՈՐՔԱՆ ԿԱՐԺԵՆԱ ՎԱՐԿԸ ՁԵԶ
                    ՀԱՄԱՐ՝ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԸ ԵՎ ՎԱՐԿԻ ՏՐԱՄԱԴՐՄԱՆ ԵՎ ՍՊԱՍԱՐԿՄԱՆ
                    ԳԾՈՎ ԲՈԼՈՐ ՊԱՐՏԱԴԻՐ ՎՃԱՐՆԵՐԸ ՍԱՀՄԱՆՎԱԾ ԺԱՄԿԵՏՆԵՐՈՒՄ ԵՎ
                    ՉԱՓԵՐՈՎ ԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ: Տարեկան փաստացի տոկոսադրույքի
                    չափը կախված է վարկի պայմաններից (գումար, ժամկետ, վարկի
                    մարմանն ուղված վճարների գումար և մարումների քանակ)
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    Տարեկան փաստացի տոկոսադրույքը հաշվարկվում է հետևյալ
                    բանաձևով`
                    <img width="180" height="60" class="banacev" />
                  </td>
                </tr>
                <tr>
                  <td colSpan="5">
                    որտեղ`
                    <strong>A</strong> վարկի գումարը (վարկատուի կողմից
                    վարկառուին տրամադրված վարկի սկզբնական գումարը)
                    <strong>n</strong> վարկի մարմանն ուղղված վճարի թիվը N վարկի
                    մարմանն ուղղված վերջին վճարի թիվը
                    <strong>Kn</strong> վարկի մարմանն ուղղված n-րդ վճարի գումարը
                    <strong>Dn</strong> վարկի տրամադրման օրից մինչև վարկի
                    մարմանն ուղղված n-րդ վճարի կատարման օրն ընկած
                    ժամանակահատվածը` արտահայտված օրերի թվով
                    <strong>i</strong> տարեկան փաստացի տոկոսադրույքը, որը կարող
                    է հաշվարկվել, եթե հավասարման մյուս տվյալները հայտնի են
                    վարկավորման պայմանագրից կամ այլ կերպ
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԱՇՎԱՐԿՄԱՆ ՕՐԻՆԱԿ
                  </td>
                </tr>
                <tr>
                  <td>Վարկի գումար</td>
                  <td>3,000,000 ՀՀ դրամ </td>
                </tr>
                <tr>
                  <td>Անվանական տարեկան տոկոսադրույք</td>
                  <td>21%</td>
                </tr>
                <tr>
                  <td>Վարկի ժամկետ</td>
                  <td>36 ամիս</td>
                </tr>
                <tr>
                  <td>Վարկի տրամադրման միանվագ վճար </td>
                  <td>վարկի գումարի 0%</td>
                </tr>
                <tr>
                  <td>Սպասարկման ամենամսյա վճար</td>
                  <td>1.5%</td>
                </tr>
                <tr>
                  <td>Մարումներ</td>
                  <td>անուիտետային </td>
                </tr>
                <tr>
                  <td>Վարկի տրամադրման օր</td>
                  <td>03 մարտ 2024թ.</td>
                </tr>
                <tr>
                  <td>Առաջին մարման օր</td>
                  <td>03 մարտ 2024թ.</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <strong> Տարեկան փաստացի տոկոսադրույք` 46.8%   </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsFizAndz;
