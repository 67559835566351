import React from "react";
import label from "../../data/labels";
import {
  SelectGroup,
  TextInput,
  Checkbox
} from "react-bootstrap4-form-validation";
import { termsAgreementModal } from "../../data/utility";
import TermsModal from "../modals/TermsModal";
import TermsQaxvacqModal from "../modals/TermsQaxvacqModal";
import TermsFizAndzModal from "../modals/TermsFizAndzModal";
import TermsAntivirus1Modal from "../modals/TermsAntivirus1Modal";
import TermsAntivirus2Modal from "../modals/TermsAntivirus2Modal";
import TermsTetevacumModal from "../modals/TermsTetevacumModal"
import TermsExtractFlexModal from "../modals/TermsExtractFlexModal";


function read(onSuccess, file) {
  var reader = new FileReader();

  reader.onload = function() {
    onSuccess(reader.result);
  };

  reader.readAsDataURL(file);
}


// // First, you need to create the `numberMask` with your desired configurations
// const numberMask = createNumberMask({
//     prefix: '',
//     suffix: '', // This will put the dollar sign at the end, with a space.
//     allowNegative: false
// })

class BorrowerFinInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props
    };

    this.handler = this.handler.bind(this);
    this.handlerUpload = this.handlerUpload.bind(this);
    this.getCertificatesField = this.getCertificatesField.bind(this);
    this.getBusinessRulesField = this.getBusinessRulesField.bind(this);
    //this.companyLogoLabel = this.companyLogoLabel.bind(this);
    this.handlerAgreement = this.handlerAgreement.bind(this);
    this.handlerLocalState = this.handlerLocalState.bind(this);
  }

  handler(newState) {
    this.setState({ ...newState }, () => this.props.handler(this.state));
  }

  handlerChange = name => event => {
    const { value } = event.target;
    let clone = { ...this.state };
    let obj = name.split(".");

    if (obj[obj.length - 1] === "currency") {
      this.props.reset();
    }

    this.setValue(obj, clone, value);
    this.setState({ ...clone });
  };


  handlerAgreement = () => event => {
    //const { value } = event.target;
    let clone = { ...this.state };

    clone.uiSupport.termsAgreementStatus = !clone.uiSupport
      .termsAgreementStatus;
    if (clone.uiSupport.termsAgreementStatus === true) {
      termsAgreementModal();
    }
    this.setState({ ...clone });
  };

  handlerLocalState(newState) {
    this.setState({ ...newState });
  }

  setValue(obj, clone, value) {
    if (obj.length === 1) {
      clone[obj] = value;
    } else {
      this.setValue(obj.slice(1), clone[obj[0]], value);
    }
  }

  /**
   * Handler gets uploaded files data
   */
  handlerUpload = name => event => {
    const files = Array.from(event.target.files);

    const formData = [];

    files.forEach((file, i) => {
      read(function(r) {
        formData.push({
          fileName: file.name,
          fileType: file.type,
          fileContent: r.split(",")[1]
        });
      }, file);
    });

    let clone = { ...this.state };
    clone.borrower.attachments[name] = formData; // formData;

    this.setState({ ...clone });
  };

  componentDidMount() {
    /* Change background */
    let clone = { ...this.state };
    clone.bg = "background2";
    this.setState({ ...clone }, () => this.handler(this.state));

    mountInputFileComponent();
  }

  getCertificatesField() {
    const upIcon = (
      <strong>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="17"
          viewBox="0 0 20 17"
        >
          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
        </svg>
      </strong>
    );

    if (this.state.borrower.borrowerType !== "personal") {
      return (
        <div className="">
          <div className="box">
            <input
              type="file"
              name="certificate[]"
              id="certificate"
              className="inputfile inputfile-7"
              data-multiple-caption={label.selectedPicCount}
              multiple
              onChange={this.handlerUpload("certificate")}
              accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <label htmlFor="certificate">
              <span>
                {this.state.borrower.borrowerType === "corp"
                  ? label.registrationLabel
                  : label.registrationExistsLabel}
              </span>
              {upIcon}
            </label>
            <div className="uploaded-info"></div>
          </div>
        </div>
      );
    } else return <></>;
  }
  getCompanyLogoLabel() {
    const upIcon = (
      <strong>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="17"
          viewBox="0 0 20 17"
        >
          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
        </svg>
      </strong>
    );
    var labelCaption;

    if(!["72","73"].includes(this.state.borrower.productTypes,0))
      labelCaption=label.companyLogoLabel;
    else
      labelCaption=label.companyReportsLabel;

    if (
      this.state.borrower.borrowerType !== "personal" &&
      this.state.borrower.productTypes == 45
    ) {
      return (
        <div className="">
          <div className="box">
            <input
              type="file"
              name="businessLogo[]"
              id="businessLogo"
              className="inputfile inputfile-7"
              data-multiple-caption={label.selectedPicCount}
              multiple
              onChange={this.handlerUpload("businessLogo")}
              accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <label htmlFor="businessLogo">
              <span>{label.varkatesakLogoLabel}</span>

              {upIcon}
            </label>
            <div className="uploaded-info"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="">
          <div className="box">
            <input
              type="file"
              name="businessLogo[]"
              id="businessLogo"
              className="inputfile inputfile-7"
              data-multiple-caption={label.selectedPicCount}
              multiple
              onChange={this.handlerUpload("businessLogo")}
              accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <label htmlFor="businessLogo">
              <span>{labelCaption}</span>
              {upIcon}
            </label>
            <div className="uploaded-info"></div>
          </div>
        </div>
      );
    }
  }
  getBusinessRulesField() {
    const upIcon = (
      <strong>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="17"
          viewBox="0 0 20 17"
        >
          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
        </svg>
      </strong>
    );

    if (this.state.borrower.borrowerType === "corp") {
      return (
        <div className="">
          <div className="box">
            <TextInput
              type="file"
              name="businessRules[]"
              id="businessRules"
              className="inputfile inputfile-7"
              data-multiple-caption={label.selectedPicCount}
              multiple
              onChange={this.handlerUpload("businessRules")}
              accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              // errorMessage={{
              //   validator: "Please choose a business rules",
              //   required: ""
              // }}
              // required
            />
            <label htmlFor="businessRules">
              <span>{label.rulesLabel}</span>
              {upIcon}
            </label>
            <div className="uploaded-info"></div>
          </div>
        </div>
      );
    } else return <></>;
  }
  getPeriodLabel() {
    const isDisabled = this.state.borrower.productId == 140;
    const minPeriod = isDisabled ? "18" : "12";
    const maxPeriod= isDisabled ? "18" : "60";

    const validatorMessage = "Խնդրում ենք մուտքագրել վավեր Ժամկետ";
    const validationMessagewithPeriod = validatorMessage + "(" + minPeriod + " - " + maxPeriod +")";

      return (
        <div className="form-group">
          <label htmlFor="txtPeriod">{this.state.borrower.productId == 34 ? label.periodLabel : this.state.borrower.productId == 140 ? label.periodLabelFlex : label.period}</label>
          <TextInput
            className="form-control input-lg"
            id="txtPeriod"
            name="period"
            maxLength="2"
            placeholder="0"
            type="number"
            min={minPeriod}
            max={maxPeriod}
            disabled={isDisabled}
            value={this.state.borrower.businessMainInformation.period}
            onChange={this.handlerChange(
              "borrower.businessMainInformation.period"
            )}
            errorMessage={{
              validator: validatorMessage,
              required: validationMessagewithPeriod,
              min: validationMessagewithPeriod,
              max: validationMessagewithPeriod
            }}
            required
          />
        </div>
      );
  }
  render() {
    const selected = this.state.borrower.productId == 34 ? {
      min: 350000,
      max: 3000000,
      message: '(350,000 - 3,000,000)'
    } : this.state.borrower.productId == 140 ? {
      min: 100000,
      max: 400000,
      message: '(100,000 - 400,000)'
    } : {
      min: 350000,
      max: 3000000,
      message: ''
    }

    const upIcon = (
      <strong>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="17"
          viewBox="0 0 20 17"
        >
          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
        </svg>
      </strong>
    );

    let businessAdvisers = label.lists.businessAdvisers.map((b,i) =>
      <option key={b.key} value = {b.key}> {b.value} </option>
    );

    let productTypes = label.lists.newProductTypes.map((pd, i) => (
        <option key={i} value={pd.id}>
          {pd.name}
        </option>
    ));

    return (
      <div>
        <div className="row js">
          <div className="col-sm-4 infoBlock">
            <div>
              <div className="form-group">
                <label htmlFor="productId">{label.productTypes}</label>
                <SelectGroup name="productId" id="productId"
                     value={this.state.borrower.productId}
                     onChange={(e) => {
                       this.handlerChange('borrower.productId')(e);
                       if(e.target.value == "140"){
                         this.handlerChange(
                             "borrower.businessMainInformation.period"
                         )({ target: { value: 18 } })
                       }
                     }}
                     required>
                  <option value="">{label.selectPlaceholder}</option>
                  {productTypes}
                </SelectGroup>
              </div>
              <div className="form-group">
                <label htmlFor="txtAmount">{this.state.borrower.productId == 34 ? label.askedAmountLabel : this.state.borrower.productId == 140 ? label.askedAmountLabelFlex : label.amountLabel}</label>
                <TextInput
                    className="form-control input-lg"
                    id="txtAmount"
                    name="amount"
                    maxLength="10"
                    placeholder="0"
                    type="number"
                    min={selected.min}
                    max={selected.max}
                    value={this.state.borrower.businessMainInformation.amount}
                    onChange={this.handlerChange(
                        "borrower.businessMainInformation.amount"
                    )}
                    errorMessage={{
                      required:
                          "Խնդրում ենք մուտքագրել վավեր գումար " + selected.message,
                      validator:
                          "Խնդրում ենք մուտքագրել վավեր գումար " + selected.message,
                      min:
                          "Խնդրում ենք մուտքագրել վավեր գումար " + selected.message,
                      max:
                          "Խնդրում ենք մուտքագրել վավեր գումար " + selected.message
                    }}
                    required
                />
              </div>

              {this.getPeriodLabel()}

              <div className="infoBlock">
                {/* <div><a href="https://acra.am" target="blank" className="link">{label.acraLabel}</a></div> */}
                <div>
                  <Checkbox
                      name="terms"
                      id="terms"
                      label={label.termsLabel}
                      onChange={this.handlerAgreement()}
                      errorMessage={label.agreementValidationText}
                      value={this.state.uiSupport.termsAgreementStatus}
                      required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 infoBlock">
            <div className="form-group">
              <label htmlFor="ddlBusinessAdvisor">{label.businessAdviserLabel}</label>
              <SelectGroup name="businessAdvisor" id="ddlBusinessAdvisor"
                           value={this.state.borrower.businessAdvisor}
                           onChange={this.handlerChange('borrower.businessAdvisor')}
              >
                <option value="">{label.selectPlaceholder}</option>
                  {businessAdvisers}
                </SelectGroup>
              </div>
            </div>
          </div>
        {this.state.borrower.productId == 140 ? <TermsExtractFlexModal handler={this.handlerLocalState} {...this.state}/> : this.state.borrower.borrowerType !== "personal" &&
        this.state.borrower.productTypes == 45 ? (
          <TermsQaxvacqModal handler={this.handlerLocalState} {...this.state} />
        ) : this.state.borrower.productTypes=="72"?(<TermsAntivirus1Modal handler={this.handlerLocalState} {...this.state}/>) :
        this.state.borrower.productTypes=="73"?(<TermsAntivirus2Modal handler={this.handlerLocalState}{...this.state}/>) :
        this.state.borrower.productTypes=="33"?(<TermsTetevacumModal handler={this.handlerLocalState}{...this.state}/>) :
        this.state.borrower.borrowerType === "personal" ? (
          <TermsFizAndzModal handler={this.handlerLocalState} {...this.state} />
        ) :
          (
          <TermsModal handler={this.handlerLocalState} {...this.state} />
        )}
      </div>
    );
  }
}

export default BorrowerFinInfo;

function mountInputFileComponent() {
  (function(document, window, index) {
    var inputs = document.querySelectorAll(".inputfile");
    Array.prototype.forEach.call(inputs, function(input) {
      var label = input.nextElementSibling,
        labelVal = label.innerHTML;

      input.addEventListener("change", function(e) {
        var fileName = "";
        if (this.files && this.files.length > 0)
          fileName = (this.getAttribute("data-multiple-caption") || "").replace(
            "{count}",
            this.files.length
          );
        else fileName = e.target.value.split("\\").pop();

        if (fileName) label.querySelector("span").innerHTML = fileName;
        else label.innerHTML = labelVal;
      });

      // Firefox bug fix
      input.addEventListener("focus", function() {
        input.classList.add("has-focus");
      });
      input.addEventListener("blur", function() {
        input.classList.remove("has-focus");
      });
    });
  })(document, window, 0);
}
