import React from "react";
import "./Terms.css";
import { green } from "@material-ui/core/colors";

class TermsExtractFlex extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...props };
    }

    render() {
        return (
            <div>
                <div className="tHead">«ՄԻԿՐՈ ԿԱՊԻՏԱԼ ՀԱՅԱՍՏԱՆ» ՈՒՎԿ ՓԲԸ</div>
                <div className="tHead">
                    Հաստատված է Տնօրենների Խորհրդի Թիվ 27/24 որոշմամբ
                </div>
                <div className="mainHeader">
                    ՍՊԱՌՈՂԱԿԱՆ ՎԱՐԿ «ՖԼԵՔՍ» ՎԱՐԿԱՏԵՍԱԿԻ ՊԱՅՄԱՆՆԵՐԸ ԵՎ ՍԱԿԱԳՆԵՐԸ
                </div>
                <div className="termsContainer">
                    <table className="termsTable smallAmount ">
                        <tbody>
                        <tr>
                            <td colSpan="2">Վարկի գումար (ՀՀ դրամ)</td>
                            <td colSpan="3">100,000-400,000</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Վարկի ժամկետ (ամիս)</td>
                            <td colSpan="3">18</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Տարեկան անվանական տոկոսադրույք</td>
                            <td colSpan="3">0%</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Տարեկան փաստացի տոկոսադրույք</td>
                            <td colSpan="3">43.2%-88.82%</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Սպասարկման ամենամսյա վճար</td>
                            <td colSpan="3">
                                <p>=100,000-ի դեպքում 3000 դրամ,</p>
                                <p>&lt;=200,000-ի դեպքում 5000 դրամ</p>
                                <p>&lt;=300,000-ի դեպքում 7000 դրամ</p>
                                <p>&lt;=400,000-ի դեպքում 9000 դրամ</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">Տրամադրման միանվագ միջնորդավճար</td>
                            <td colSpan="3">0%</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Վաղաժամկետ մարում</td>
                            <td colSpan="3">0%</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Արտոնյալ ժամկետ (մայր գումար)</td>
                            <td colSpan="3">առավելագույնը 6 ամիս</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Ապահովվածություն(նվազագույն)</td>
                            <td colSpan="3">
                                -
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">Վաղաժամկետ մարում</td>
                            <td colSpan="3">0%</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Տույժեր</td>
                            <td colSpan="3">
                                ժամկետանց տոկոսագումարի և մայր գումարի նկատմամբ օրական 0.1%
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <h3 className="mainHeader">ՍՊԱՌՈՂԱԿԱՆ ՎԱՐԿ «ՖԼԵՔՍ» ՎԱՐԿԱՏԵՍԱԿԻ ՆԿԱՐԱԳԻՐԸ ԵՎ ՀԻՄՆԱԿԱՆ ԲՆՈՒԹԱԳՐԵՐԸ</h3>
                <div className="termsContainer">
                    <table className="termsTable mainDesc">
                        <tbody>
                        <tr>
                            <td colSpan="1">N</td>
                            <td colSpan="2">ՉԱՓԱՆԻՇՆԵՐ</td>
                            <td colSpan="2">ՀԻՄՆԱԿԱՆ ԲՆՈՒԹԱԳՐԵՐ</td>
                        </tr>
                        <tr>
                            <td colSpan="1">1</td>
                            <td colSpan="2">Վարկառուի նկարագիր</td>
                            <td colSpan="2">
                                ՀՀ քաղաքացի և/կամ ռեզիդենտ ֆիզիկական անձիք
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">2</td>
                            <td colSpan="2">
                                Վարկառուին ներկայացվող պահանջներ
                            </td>
                            <td colSpan="2">
                                <ul>
                                    <li>
                                        Վարկառուի տարիքը 21 – 65 տարեկան` համապատասխանաբար դիմելու պահին և
                                        վարկային պայմանագրի ժամկետի ավարտի պահին,
                                    </li>
                                    <li>
                                        Վարկառուի դրական վարկային պատմություն՝ «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ
                                        ՓԲԸ–ի ներքին չափանիշներին համապատասխան,

                                    </li>
                                    <li>
                                        Վարկառուի համապատասխանություն «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ - ի
                                        վարկային քաղաքականությանը և ներքին կանոնակարգերով սահմանված
                                        այլ չափանիշներին
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">3</td>
                            <td colSpan="2">Վարկի նպատակ</td>
                            <td colSpan="2">Սպառողական</td>
                        </tr>
                        <tr>
                            <td colSpan="1">4</td>
                            <td colSpan="2">Վարկի արժույթ</td>
                            <td colSpan="2">ՀՀ դրամ</td>
                        </tr>
                        <tr>
                            <td colSpan="1">5</td>
                            <td colSpan="2">
                                Վարկի տրամադրման նվազագույն և առավելագույն սահմանաչափեր
                            </td>
                            <td colSpan="2">100,000 – 400,000 ՀՀ դրամ</td>
                        </tr>
                        <tr>
                            <td colSpan="1">6</td>
                            <td colSpan="2">Վարկի առավելագույն ժամկետներ</td>
                            <td colSpan="2">18 ամիս</td>
                        </tr>
                        <tr>
                            <td colSpan="1">7</td>
                            <td colSpan="2">Մարման ժամանակացույց</td>
                            <td colSpan="2">
                                Ճկուն մարման ժամանակացույց` առաջին 6 ամիսների ընթացքում միայն սպասարկման վճար,
                                այնուհետև վարկի մայր գումարի մարումները բաշխվում են մարման ժամանակաշրջանի վրա
                                հավասարաչափ
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">8</td>
                            <td colSpan="2">Արտոնյալ ժամկետ մայր գումարի համար</td>
                            <td colSpan="2">
                                առավելագույնը 6 ամիս
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">9</td>
                            <td colSpan="2">Վարկի տրամադրման եղանակ</td>
                            <td colSpan="2">
                                Անկանխիկ
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">10</td>
                            <td colSpan="2">Վաղաժամկետ մարում</td>
                            <td colSpan="2">
                                <ul>
                                    <li>
                                        Վարկառուն իրավունք ունի վաղաժամկետ ամբողջական կամ մասնակի մարելու վարկը
                                        և պայմանագրով սահմանված այլ պարտավորությունները,
                                    </li>
                                    <li>
                                        Վարկի վաղաժամկետ ամբողջական մարման պարագայում վարկառուն վճարում է
                                        վճարման օրվա դրությամբ հաշվարկված վարկի սպասարկման և այլ վճարները,
                                        տոկոսները և վարկի մայր գումարը,
                                    </li>
                                    <li>
                                        Վարկի վաղաժամկետ մասնակի մարման պարագայում գումարը ուղղվում է վարկի մայր
                                        գումարի մարմանը և համամասնորեն նվազեցվում են վարկի հետագա ամսվա
                                        (ամիսների) մարման ենթակա տոկոսագումարները, եթե հաճախորդը չի
                                        տրամադրել այլ հանձնարարական:
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">11</td>
                            <td colSpan="2">
                                Զուգահեռ վարկեր վարկառուների խմբին/փոխկապակցված անձանց տվյալ
                                վարկատեսակի շրջանակներում
                            </td>
                            <td colSpan="2">
                                <ul>
                                    <li>Զուգահեռ վարկերի առավելագույն քանակ - 2</li>
                                    <li>
                                        Զուգահեռ վարկերի առավելագույն գումարը – 400,000 ՀՀ դրամ
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">12</td>
                            <td colSpan="2">
                                Զուգահեռ վարկեր վարկառուների խմբին/փոխկապակցված անձանց ՄԿՀ-ում
                            </td>
                            <td colSpan="2">
                                <ul>
                                    <li>Զուգահեռ վարկերի առավելագույն քանակ - 3</li>
                                    <li>
                                        Զուգահեռ վարկերի առավելագույն գումարը – 12,000,000 ՀՀ դրամ, միաժամանակ,
                                        առանց երաշխավորության զուգահեռ վարկերի առավելագույն գումարը - 5,000,000 ՀՀ դրամ
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">13</td>
                            <td colSpan="2">Տրանշեր</td>
                            <td colSpan="2">-</td>
                        </tr>
                        <tr>
                            <td colSpan="1">14</td>
                            <td colSpan="2">Ապահովվածություն (նվազագույն)</td>
                            <td colSpan="2">
                                -
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">15</td>
                            <td colSpan="2">Վճարունակության գնահատում</td>
                            <td colSpan="2">
                                <ul>
                                    <li>հաճախորդի սոցիալական բնութագիր,</li>
                                    <li>հաճախորդի ՖԱՅԿՈ գնահատական,</li>
                                    <li>
                                        հաճախորդի վարկային պատմություն,
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">16</td>
                            <td colSpan="2">Վարկերի դիտարկում (հաստատում/մերժում)</td>
                            <td colSpan="2">
                                Ներքին սքորինգային համակարգի հաշվարկի համաձայն
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">17</td>
                            <td colSpan="2">Որոշման կայացման ժամկետ</td>
                            <td colSpan="2">
                                30 աշխատանքային րոպե  (հաճախորդի կողմից տրամադրված ամբողջական փաթեթի առկայության դեպքում)
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">18</td>
                            <td colSpan="2">Որոշման մասին տեղեկացնելու ժամկետ</td>
                            <td colSpan="2">30 աշխատանքային րոպե</td>
                        </tr>
                        <tr>
                            <td colSpan="1">19</td>
                            <td colSpan="2">Վարկը տրամադրելու ժամկետ</td>
                            <td colSpan="2">
                                1 աշխատանքային օր (վարկի ձևակերպման համար անհրաժեշտ փաստաթղթերը ներկայացնելու դեպքում)
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">20</td>
                            <td colSpan="2">Վարկի ձևակերպման վայրը</td>
                            <td colSpan="2">
                                «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ գրասենյակներում
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">21</td>
                            <td colSpan="2">Վարկային հայտի մերժման հնարավոր պատճառներ</td>
                            <td colSpan="2">
                                <ul>
                                    <li>
                                        սահմանված նվազագույն պահանջներին չբավարարման դեպքում (1 – 5 կետեր),
                                    </li>
                                    <li>
                                        հաճախորդի կողմից անհրաժեշտ փաստաթղթերի և այլ հիմնավորումների չներկայացման,
                                        կեղծ փաստաթղթերի կամ տեղեկատվության ներկայացման դեպքում,
                                    </li>
                                    <li>
                                        վարկային կոմիտեն չի բավարարել վարկային հայտը` հաշվի առնելով գործարքի
                                        ռիսկայնությունը կամ առկա այլ ռիսկերը, վարկային կոմիտեի իրավասությամբ
                                        սահմանված այլ դեպքերում, այդ թվում.
                                        <ul>
                                            <li>
                                                դրական վարկային պատմության բացակայություն,
                                            </li>
                                            <li>
                                                իրավական ռիսկերի առկայություն,
                                            </li>
                                            <li>
                                                «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ կողմից չֆինանսավորվող
                                                ոլորտների ցանկին համապատասխանություն,
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">22</td>
                            <td colSpan="2">Մոնիթորինգ</td>
                            <td colSpan="2">
                                Համաձայն «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ Վարկավորման ընթացակարգի
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">23</td>
                            <td colSpan="2">Անհրաժեշտ փաստաթղթերի ցանկ</td>
                            <td colSpan="2">
                                <ul>
                                    <li>
                                        Հաճախորդի անձնագիր և սոցիալական քարտ կամ նույնականացման
                                        քարտ,
                                    </li>
                                    <li>Այլ փաստաթղթեր (Վարկային Կոմիտեի պահանջով)</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">24</td>
                            <td colSpan="2">
                                Տարեկան տոկոսադրույք և վարկատեսակի այլ պայմաններ
                            </td>
                            <td colSpan="2">
                                Վարկատեսակի տոկոսադրույքը, տրամադրման վճարը և այլ պայմանները սահմանվում
                                են «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ լիազոր մարմնի որոշմամբ հաստատված
                                վարկատեսակների պայմանների և սակագների մեջ, որի մասին հաճախորդները
                                ծանուցվում են կազմակերպության բոլոր տարածքային ստորաբաժանումներում,
                                ինչպես նաև պայմանները նկարագրող ինֆորմացիոն թերթիկների և կազմակերպության
                                ինտերնետային կայքի միջոցով:
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">25</td>
                            <td colSpan="2">Այլ պայմաններ</td>
                            <td colSpan="2">
                                1 (մեկ) հաճախորդին տրամադրված առանց գրավ վարկերի ընդհանուր մնացորդը բոլոր
                                վարկատեսակների գծով չի կարող գերազանցել 12,000,000 ՀՀ դրամը
                                (համարժեք – 30,000 ԱՄՆ դոլար):
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1">ԶԳՈՒՇԱՑՈՒՄ</td>
                            <td colSpan="4">
                                <ul>
                                    <li>
                                        ԱՄՆ ԴՈԼԱՐՈՎ ՏՐԱՄԱԴՐՎԱԾ ՎԱՐԿԵՐԻ ՊԱՐԱԳԱՅՈՒՄ ԱՐՏԱՐԺՈՒՅԹԻ
                                        ՓՈԽԱՐԺԵՔԻ ՓՈՓՈԽՈՒԹՅՈՒՆՆԵՐԸ ԿԱՐՈՂ ԵՆ ԱԶԴԵՑՈՒԹՅՈՒՆ ՈՒՆԵՆԱԼ
                                        ՎԱՐԿԻ ՄԱՐՄԱՆ ԳՈՒՄԱՐՆԵՐԻ ՎՐԱ,
                                    </li>
                                    <li>
                                        ԱՄՆ ԴՈԼԱՐՈՎ ՏՐԱՄԱԴՐՎԱԾ ՎԱՐԿԵՐԻ ՊԱՐԱԳԱՅՈՒՄ ԱՐՏԱՐԺՈՒՅԹԻ ՓՈՓՈԽՈՒԹՅԱՆ
                                        ԳՈՐԾՈՆԸ ՉԻ ՆԵՐԱՌՎՈՒՄ ՎԱՐԿԻ ՏՐԱՄԱԴՐՄԱՆ ԺԱՄԱՆԱԿ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԻ
                                        ՀԱՇՎԱՐԿՄԱՆ ՄԵՋ, ՀԵՏԵՎԱԲԱՐ ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԸ ԿԱՐՈՂ Է
                                        ՓՈՓՈԽՎԵԼ՝ ԿԱԽՎԱԾ ՀԱՅԱՍՏԱՆԻ ՀԱՆՐԱՊԵՏՈՒԹՅԱՆ ԿԵՆՏՐՈՆԱԿԱՆ ԲԱՆԿԻ
                                        ՊԱՇՏՈՆԱԿԱՆ ԿԱՅՔ ԷՋՈՒՄ ՀՐԱՊԱՐԱԿՎԱԾ ՓՈԽԱՐԺԵՔԻ ՄԵԾՈՒԹՅԱՆ
                                        ՓՈՓՈԽՈՒԹՅՈՒՆԻՑ,
                                    </li>
                                    <li>
                                        ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ՎԱՐԿԻ ՄԱՅՐ ԳՈՒՄԱՐԻ, ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԻ ԵՎ
                                        ՍՊԱՍԱՐԿՄԱՆ ՎՃԱՐՆԵՐԻ ՄԱՐՈՒՄՆԵՐԸ ԺԱՄԱՆԱԿԻՆ ԵՎ ՊԱՏՇԱՃ ՉԿԱՏԱՐԵԼՈՒ
                                        ԴԵՊՔՈՒՄ ՎԱՐԿԱՌՈՒԻ ԵՎ ԵՐԱՇԽԱՎՈՐԻ ՄԱՍԻՆ ՀԱՄԱՊԱՏԱՍԽԱՆ
                                        ՏԵՂԵԿԱՏՎՈՒԹՅՈՒՆԸ ԿԳՐԱՆՑՎԻ ԿԲ ՎԱՐԿԱՅԻՆ ՌԵԳԻՍՏՐՈՒՄ ԵՎ «ԱՔՌԱ»
                                        ՎԱՐԿԱՅԻՆ ԲՅՈՒՐՈՅԻ ՌԵԳԻՍՏՐՈՒՄ,
                                    </li>
                                    <li>
                                        ՎԱՐԿԱՌՈՒՆ ԻՐ ՊԱՅՄԱՆԱԳՐԱՅԻՆ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ԿԱՏԱՐՄԱՆ ՀԱՄԱՐ
                                        ՆՅՈՒԹԱԿԱՆ ՊԱՏԱՍԽԱՆԱՏՎՈՒԹՅՈՒՆ Է ԿՐՈՒՄ ՆԱԵՎ ՍԵՓԱԿԱՆՈՒԹՅԱՆ ԻՐԱՎՈՒՆՔՈՎ
                                        ԻՐԵՆ ՊԱՏԿԱՆՈՂ ՑԱՆԿԱՑԱԾ ԱՅԼ ԳՈՒՅՔՈՎ (ՏՈՒՆ, ԲՆԱԿԱՐԱՆ, ԱՎՏՈՄԵՔԵՆԱ,
                                        ԱՇԽԱՏԱՎԱՐՁ ԵՎ ԱՅԼՆ), ԵԹԵ ԱՌԿԱ ԳՐԱՎԻ ԱՌԱՐԿԱՆ ԲԱՎԱՐԱՐ ՉԷ «ՄԻԿՐՈ
                                        ԿԱՊԻՏԱԼ ՀԱՅԱՍՏԱՆ» ՈՒՎԿ ՓԲԸ – Ի ՆԿԱՏՄԱՄԲ ՍՏԱՆՁՆԱԾ ԻՐ
                                        ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ԱՄԲՈՂՋԱԿԱՆ ԵՎ ՊԱՏՇԱՃ ԿԱՏԱՐՄԱՆ ՀԱՄԱՐ,
                                    </li>
                                    <li>
                                        ՎԱՐԿԱՌՈՒՆ ԵՎ ԵՐԱՇԽԱՎՈՐԸ ԻՐԵՆՑ ԱՆՁՆԱԿԱՆ ԱՄԲՈՂՋ ԳՈՒՅՔՈՎ
                                        (ՏՈՒՆ, ՄԵՔԵՆԱ, ԱՇԽԱՏԱՎԱՐՁ ԿԱՄ ԱՅԼ ԳՈՒՅՔ) ՀԱՎԱՍԱՐԱՊԵՍ
                                        ՊԱՏԱՍԽԱՆԱՏՎՈՒԹՅՈՒՆ ԵՆ ԿՐՈՒՄ «ՄԻԿՐՈ ԿԱՊԻՏԱԼ ՀԱՅԱՍՏԱՆ» ՈՒՎԿ ՓԲԸ –
                                        Ի ԱՌՋԵՎ ՍՏԱՆՁՆԱԾ ԻՐԵՆՑ ՎԱՐԿԱՅԻՆ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ԿԱՏԱՐՄԱՆ ՀԱՄԱՐ,
                                    </li>
                                    <li>
                                        ՎԱՐԿԱՌՈՒԻ ԵՎ/ԿԱՄ ԵՐԱՇԽԱՎՈՐԻ ԱՆՁՆԱԿԱՆ ԳՈՒՅՔԸ ԿԱՐՈՂ Է ԲՌՆԱԳԱՆՁՎԵԼ
                                        ՕՐԵՆՔՈՎ ՍԱՀՄԱՆՎԱԾ ԿԱՐԳՈՎ, ԵԹԵ ՎԱՐԿԱՌՈՒՆ ԺԱՄԱՆԱԿԻՆ ՉԻ ԿԱՏԱՐՈՒՄ
                                        ԻՐ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԻ, ՍՊԱՍԱՐԿՄԱՆ ՎՃԱՐՆԵՐԻ ԵՎ ՎԱՐԿԻ
                                        ԳՈՒՄԱՐԻ ՄԱՐՈՒՄՆԵՐԸ,
                                    </li>
                                    <li>
                                        ՄԻՆՉԵՎ 5,000,000 ՀՀ ԴՐԱՄ ԿԱՄ ՀԱՄԱՐԺԵՔ ԱՄՆ ԴՈԼԱՐ ՎԱՐԿ ՍՏԱՆԱԼՈՒ
                                        ՀԱՄԱՐ ԴԻՄԵԼԻՍ ՎԱՐԿԱՏՈՒՆ ՎԱՐԿԱՌՈՒԻՆ ԿՏՐԱՄԱԴՐԻ ՎԱՐԿԻ ԷԱԿԱՆ
                                        ՊԱՅՄԱՆՆԵՐԻ ԱՆՀԱՏԱԿԱՆ ԹԵՐԹԻԿ, ՈՐՈՒՄ ԿՆԵՐԿԱՅԱՑՎԵՆ ՎԱՐԿԱՌՈՒԻՆ
                                        ՏՐԱՄԱԴՐՎԵԼԻՔ ՎԱՐԿԻ ԱՆՀԱՏԱԿԱՆ ՊԱՅՄԱՆՆԵՐԸ
                                    </li>
                                    <li>
                                        ՄԻՆՉԵՎ 5,000,000 ՀՀ ԴՐԱՄ ԿԱՄ ՀԱՄԱՐԺԵՔ ԱՄՆ ԴՈԼԱՐ ՏՐԱՄԱԴՐՎԱԾ ՎԱՐԿԱՅԻՆ
                                        ՊԱՅՄԱՆԱԳՐԻ ԳՈՐԾՈՂՈՒԹՅԱՆ ԸՆԹԱՑՔՈՒՄ ՎԱՐԿԱՌՈՒԻ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ
                                        ՎԵՐԱԲԵՐՅԱԼ ՔԱՂՎԱԾՔՆԵՐԸ ԱՄԻՍԸ ՄԵԿ ԱՆԳԱՄ ՈՒՂԱՐԿՎՈՒՄ ԵՆ ՎԱՐԿԱՌՈՒԻ
                                        ԿՈՂՄԻՑ ՏՐԱՄԱԴՐՎԱԾ ԷԼԵԿՏՐՈՆԱՅԻՆ ՓԱՍՏԻ ՀԱՍՑԵԻՆ, ԵԹԵ ՎԱՐԿԱՌՈՒՆ ՔԱՂՎԱԾՔԻ
                                        ՍՏԱՑՄԱՆ ԱՅԼ ԵՂԱՆԱԿ ՉԻ ԸՆՏՐՈՒՄ: ԱՆԿԱԽ ՆՇՎԱԾ ԵՂԱՆԱԿՈՎ ՔԱՂՎԱԾՔԸ ՍՏԱՆԱԼՈՒՑ՝
                                        ՎԱՐԿԱՌՈՒՆ ԻՐԱՎՈՒՆՔ ՈՒՆԻ ԱՅՆ ՍՏԱՆԱԼ (ԴԻՄԵԼՈՒ ՊԱՀԻՆ) ՎԱՐԿԱՏՈՒԻ
                                        ՏԱՐԱԾՔՈՒՄ՝ ԱՌՁԵՌՆ։ ՔԱՂՎԱԾՔՆԵՐԻ ՏՐԱՄԱԴՐՈՒՄՆ ԱՆՎՃԱՐ Է:
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default TermsExtractFlex;
