import React from 'react';
import PropTypes from 'prop-types';
import CustomerType from '../partitions/CustomerType';

class Empty extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...props };
        this.handler = this.handler.bind(this);
    }

    handler(newState) {
        this.setState({ ...newState }, () => {
            if (this.props.handler) {
                this.props.handler(this.state);
            }
        });
    }

    render() {
        return (
            <div>
                <CustomerType handler={this.handler} {...this.state} />
            </div>
        );
    }
}

Empty.propTypes = {
    classes: PropTypes.object,
};

export default Empty;