import React from 'react';
import '../App.css';
import label from '../data/labels';
import Empty from './steps/Empty';
import Guarantor from './steps/Guarantor';
import Final from './steps/Final';
import Borrower from './steps/Borrower';
import {hideEffectiveRate} from '../data/utility';

class FormFilling extends React.Component {

  constructor(props) {
    super(props);
    this.state = { ...props };
    this.handler = this.handler.bind(this);
    this.getContent = this.getContent.bind(this);
  }

  handler = (newState) => {
    this.setState({  ...newState  });
  }

  componentWillReceiveProps(newProps) {
    this.setState({  ...newProps  });
  }

  handleEffectiveClose ()
  {
    hideEffectiveRate();
  }

  getContent() {
    switch (this.state.customerType) {
      case "0":
        return <Empty handler={this.handler} {...this.state} />;
      case "1":
        return <Borrower handler={this.handler} {...this.state} />;
      case "2":
        return <Guarantor handler={this.handler} {...this.state} />;
      default:
        return <Empty handler={this.handler} {...this.state} />;
    }
  }

  getPage() {
    switch (this.state.page) {

      case "send":
        return <div className="info">
          <Final handler={this.handler} {...this.state} />
        </div>
      case "data":
      default:
        return <div className="info">
          <h1 dangerouslySetInnerHTML={{ __html: label.formFilling }}></h1>
          {this.getContent()}
        </div>
    }
  }

  render() {
    let bgClass = "pageSection formFilling " + this.state.bg;

    return (
      <div>
        <div className={bgClass}>
          {this.getPage()}


        </div>
        <div className="effective-rate" >
        <div className="effective-rate-content" >
        ՈՒՇԱԴՐՈՒԹՅՈՒՆ՝ ՏԵՂԵԿԱՑՆՈՒՄ ԵՆՔ, ՈՐ ԱՅՍ ՎԱՐԿԱՏԵՍԱԿՆԵՐՆ ՈՒՆԵՆ ԲԱՐՁՐ  ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔ: ՈՒՍՏԻ,<br/> ՆԱԽԱՊԵՍ ԴԻՏԱՐԿԵՔ ՇՈՒԿԱՅՈՒՄ ԱՌԿԱ ՆՄԱՆԱՏԻՊ ԱՅԼ ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐԸ ԵՎ ԳՆԱՀԱՏԵՔ ՎԱՐԿԸ ՄԱՐԵԼՈՒ ՁԵՐ ՀՆԱՐԱՎՈՐՈՒԹՅՈՒՆՆԵՐԸ:
        <br/>
        <div className="effective-rate-close" onClick={this.handleEffectiveClose}>Փակել</div>
        </div>
        </div>
      </div>

    );
  }
}

export default FormFilling;
